.headerLang {
  position: relative;
  margin: auto 0;
  padding: 0 15px;
}

.headerLang__itemContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.headerLang__item {
  padding: 0 7px 0 0;
  text-transform: uppercase;
}

.headerLang__optionsItem {
  transition: all 0.1s ease-out;
  cursor: pointer;
  padding: 0 15px 0;
}

.headerLang__optionsItem:hover {
  color: var(--color-accent);
}

.headerLang .headerLang__itemContainer {
  transition: color 0.3s ease-out;
}

.headerLang:hover .headerLang__itemContainer {
  color: var(--color-accent);
}
