.headerLang__bottomArrow {
  transition: all 0.1s ease-out;
  width: 11px;
}
.headerLang__options {
  display: none;
  position: absolute;
  left: 0;
  margin: 0;
  background-color: var(--color-white);
  padding: 5px 5px 5px 0;
  list-style-type: none;
}

.headerLang__bottomArrow.active {
  transform: rotate(180deg);
  width: 11px;
}

.headerLang__options.active {
  display: block;
  color: var(--color-black);
}
