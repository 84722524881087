.header__contact {
  position: relative;
  opacity: 0.85;
  transition: all 0.3s ease-out;
  cursor: pointer;
  margin: auto 0 auto 30px;
  border: 0;
  border-bottom: none;
  background: transparent;
  height: fit-content;
  color: inherit;
  font-weight: 600;
  text-transform: uppercase;
  white-space: nowrap;
}

@media screen and (max-width: 1000px) {
  .header__contact {
    display: none;
  }
}

.header__contact:hover {
  color: var(--color-accent);
}

.header__contact:visited {
  color: var(--color-visited);
}

.header__contact::after {
  position: absolute;
  right: 0;
  bottom: -3px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0.3s;
  margin-right: auto;
  margin-left: auto;
  background: var(--color-white);
  width: 100%;
  height: 1px;
  content: "";
}

.header__contact:hover::after {
  transition: all 0.3s ease-out;
  width: 0;
}

.header__contact::before {
  position: absolute;
  right: 0;
  bottom: -3px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out;
  margin-right: auto;
  margin-left: auto;
  background: var(--color-accent);
  width: 0;
  height: 1px;
  content: "";
}

.header__contact:hover::before {
  transition: all 0.3s ease-out 0.3s;
  width: 100%;
}

.header_dark .header__contact::after {
  border-bottom: 1px solid var(--color-black);
  background: var(--color-black);
}
